<template>
  <div>
    <v-dialog v-model="dialogBulkOptions" max-width="600">
      <v-card>
        <v-card-title>{{ $t('bulk_add_options') }}</v-card-title>
        <v-card-subtitle>{{ $t('bulk_add_instructions') }}</v-card-subtitle>
        <v-card-text>
          <v-textarea v-model.trim="bulkOptions"
                      outlined
                      dense
                      :label="$t('options')"
                      rows="8"
                      hide-details
                      auto-grow></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="dialogBulkOptions = false">{{ $t('button_close') }}</v-btn>
          <v-btn color="success" text @click="bulkAddOptions">{{ $t('add') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" max-width="400">
      <v-card>
        <v-card-title>{{ $t('confirm_generic') }}</v-card-title>
        <v-card-actions>
          <v-btn color="secondary"  text @click="dialogDelete = false">{{ $t('button_cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="deleteField">{{ $t('button_yes') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row no-gutters>
      <v-col cols="12" class="d-flex align-center mb-4 pr-2">
        <v-select v-model="field.type"
                  :items="fieldTypes"
                  item-value="type"
                  item-text="label"
                  outlined
                  hide-details
                  dense
                  :label="$t('type')"
                  @change="setType"
                  class="mr-2"
        ></v-select>
        <v-select v-model="field.weight"
                  v-if="field.type !== 'text' && field.type !== 'number' && field.type !== 'date'"
                  :items="fieldWeights"
                  item-value="weight"
                  item-text="label"
                  outlined
                  hide-details
                  dense
                  :label="$t('weight')"
                  @change="setType"
                  class="mr-2"
        >
        </v-select>
        <v-switch
            v-model="field.required"
            hide-details
            :label="$t('required')"
            class="mr-2"
        ></v-switch>
        <v-switch
            v-if="field.type === 'mc'"
            v-model="field.additive"
            hide-details
            :label="$t('additive')"
            class="mr-2"
        ></v-switch>
        <v-switch
            v-if="['country', 'rcountry', 'country-risks'].includes(field.type)"
            v-model="field.unknown_option"
            hide-details
            :label="$t('unknown_option')"
            class="mr-2"
        ></v-switch>
        <v-switch
            v-if="['country', 'rcountry', 'country-risks', 'select'].includes(field.type)"
            v-model="field.multiselect"
            hide-details
            :label="$t('multiselect')"
            class="mr-2"
        ></v-switch>
        <v-select
            :items="optionViewModes"
            v-model="field.viewmode"
            :label="$t('presentation')"
            outlined
            dense
            hide-details
            item-text="label"
            item-value="value"
            class="mr-2"
            v-if="field.type === 'sc' || field.type === 'mc'">
        </v-select>
        <v-btn icon @click.stop="dialogDelete = true">
          <v-icon>{{ icons.mdiDelete }}</v-icon>
        </v-btn>
        <v-btn icon @click.stop="dialogBulkOptions = true" class="ml-2"
               v-if="field.type === 'sc' || field.type === 'mc'">
          <v-icon>{{ icons.mdiFormatListChecks }}</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col class="mb-4">
        <v-textarea
            v-model.trim="field.title"
            outlined
            dense
            :label="$t('field_title')"
            rows="2"
            hide-details
            auto-grow
            @change="$emit('changed')"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col class="mb-4">
        <v-textarea
            v-model.trim="field.instruction"
            outlined
            dense
            :label="$t('instruction')"
            rows="2"
            hide-details
            auto-grow
            @change="$emit('changed')"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row no-gutters v-if="['country', 'rcountry', 'country-risks'].includes(field.type)">
      <v-col class="mb-4 d-flex">
        <v-switch
            v-model="field.hqCountry"
            hide-details
            :label="$t('hq_country')"
            class="mr-4"
            @change="$emit('changed')"
        ></v-switch>
        <v-switch
            v-model="field.productionCountry"
            hide-details
            :label="$t('production_country')"
            @change="$emit('changed')"
        ></v-switch>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col class="mb-4 pr-2">
        <v-text-field
            v-model.trim="linkUrl"
            outlined
            dense
            :label="$t('link_url')"
            hide-details
            @change="setLink"
        ></v-text-field>
      </v-col>
      <v-col class="mb-4 pl-2">
        <v-text-field
            v-model.trim="linkLabel"
            outlined
            dense
            :label="$t('link_label')"
            hide-details
            @change="setLink"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col class="mb-4 pr-2">
        <v-select
            v-model="field.automation.prop"
            :items="supplierDataProps"
            outlined
            dense
            :label="$t('supplier_data_automation')"
            hide-details
            @change="setAutomationPropType"
        ></v-select>
      </v-col>
    </v-row>
    <v-row v-if="['sc', 'mc', 'country', 'rcountry', 'select', 'country-risks'].includes(field.type)"
           no-gutters>
      <v-col>
        <div class="subtitle-1 mb-2">{{ $t('options') }}</div>
        <option-table :fields="fields.filter(f => f.id !== field.id)" :field="field"
                      :key="optionsKey"
                      @changed="$emit('changed')"></option-table>
      </v-col>
    </v-row>
    <v-row v-if="['nace'].includes(field.type)"
           no-gutters>
      <v-col>
        <div class="subtitle-1 mb-2">{{ $t('exceptions') }}</div>
        <exception-table :fields="fields.filter(f => f.id !== field.id)" :field="field"
                      :key="optionsKey"
                      @changed="$emit('changed')"></exception-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import OptionTable from './OptionTable';
import ExceptionTable from './ExceptionTable';
import {
  mdiDelete,
  mdiDragVertical,
  mdiCircle,
  mdiInformation,
  mdiFormatListChecks
} from '@mdi/js';
import short from '../shortuid';
import {useCoreStore} from '../stores/core';

export default {
  name: 'Field',
  setup() {
    const coreStore = useCoreStore();
    return {coreStore};
  },
  props: {
    field: Object,
    fields: Array,
    index: Number,
  },
  created() {
    this.initField();
  },
  components: {
    OptionTable,
    ExceptionTable,
  },
  data: () => ({
    dialogDelete: false,
    dialogBulkOptions: false,
    componentKey: 0,
    rated: false,
    linkUrl: null,
    bulkOptions: '',
    linkLabel: null,
    optionsKey: 0,
    optionViewModes: [
      {value: 'flow', label: 'Flow'},
      {value: 'columns', label: 'Columns'},
      {value: 'rows', label: 'Rows'},
    ],
    ratings: [
      {
        label: 'Green',
        result: 0,
      },
      {
        label: 'Yellow',
        result: 1,
      },
      {
        label: 'Red',
        result: 2,
      },
      {
        label: 'Critical',
        result: 3,
      },
    ],
    supplierDataProps: [
      {value: null, text: '', type: null},
      {value: 'details.nace', text: 'NACE code', type: 'string'},
      {value: 'country', text: 'Country', type: 'string'},
      {value: 'details.operating_countries', text: 'Operating countries', type: 'array'},
      {value: 'details.revenue', text: 'Revenue', type: 'number'},
      {value: 'spend', text: 'Spend (entered)', type: 'number'},
      {value: 'spend_converted', text: 'Spend (default currency)', type: 'number'},
      {value: 'employees', text: 'Employees', type: 'number'},
    ],
    ratingColors: ['success', 'warning', 'error'],
    fieldWeights: [
      {weight: 0, label: 'None'},
      {weight: 1, label: 'Low'},
      {weight: 2, label: 'Normal'},
      {weight: 3, label: 'High'},
    ],
    fieldTypes: [
      {
        type: 'text',
        label: 'Text'
      },
      {
        type: 'number',
        label: 'Number'
      },
      {
        type: 'nace',
        label: 'NACE code'
      },
      {
        type: 'date',
        label: 'Date'
      },
      {
        type: 'select',
        label: 'Select'
      },
      {
        type: 'sc',
        label: 'Single choice'
      },
      {
        type: 'mc',
        label: 'Multiple choice'
      },
      {
        type: 'country-risks',
        label: 'Country selector (categories, risk breakdown)'
      },
      {
        type: 'rcountry',
        label: 'Country selector (categories, no risk breakdown)'
      },
      {
        type: 'country',
        label: 'Country selector (no risk breakdown)'
      },
      {
        type: 'nace-country',
        label: 'NACE code/Country (experimental)'
      },
    ],
    icons: {
      mdiDelete,
      mdiDragVertical,
      mdiCircle,
      mdiInformation,
      mdiFormatListChecks
    },
  }),
  methods: {
    setAutomationPropType() {
      const item = this.supplierDataProps.find(i => i.value === this.field.automation.prop);
      this.field.automation.type = item?.type;
    },
    deleteField() {
      this.dialogDelete = false;
      this.$emit('delete', this.index);
    },
    bulkAddOptions() {
      if (this.bulkOptions) {
        const options = this.bulkOptions.split('\n');
        if (!this.field.options) {
          this.field.options = [];
        }
        for (const option of options) {
          this.field.options.push({
            id: short.generate(),
            value: option.trim(),
            risk: 1,
            flag: 0,
            combinations: [],
          });
        }
        this.$emit('changed');
        this.optionsKey += 1;
        this.dialogBulkOptions = false;
        this.bulkOptions = '';
      }
    },
    setLink() {
      if (this.linkUrl && this.linkLabel) {
        this.field.link = {url: this.linkUrl, label: this.linkLabel};
      } else {
        delete this.field.link;
      }
      this.$emit('changed');
    },
    initField() {
      if (this.field.link?.url) this.linkUrl = this.field.link.url;
      if (this.field.link?.label) this.linkLabel = this.field.link.label;
      this.setType();
    },
    setType() {
      if (this.field.type === 'country' || this.field.type === 'rcountry' || this.field.type === 'select') {
        this.field.multiselect ??= false;
      } else if (this.field.type === 'mc' || this.field.type === 'sc') {
        this.field.viewmode ??= 'flow';
      } else {
        delete this.field.multiselect;
      }
    }
  }
};
</script>

<style scoped>
.v-input--selection-controls {
  margin-top: 0;
}
</style>
